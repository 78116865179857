var render = function() {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c(
    "div",
    { staticClass: "popup__body p-0" },
    [
      _c("app-dialog-close"),
      _c("h3", [_vm._v("Заполните анкету")]),
      _c("iframe", {
        staticClass: "d-block",
        class: { hidden: !_vm.loaded },
        attrs: {
          src: "https://partner.unomika.com/rielty",
          frameborder: "0",
          width: "100%",
          height: "620"
        },
        on: {
          load: function($event) {
            _vm.loaded = true
          }
        }
      })
    ],
    1
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }