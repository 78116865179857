var render = function() {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c("div", { staticClass: "page-wrapper" }, [
    _c("div", { staticClass: "wrapper-padding_lg credit" }, [
      _vm._m(0),
      _c(
        "div",
        { staticClass: "credit-form" },
        [
          _c(
            "app-form",
            {
              staticClass: "form-row",
              attrs: {
                model: _vm.model,
                rules: _vm.validationConfig,
                "request-handler": _vm.sendForm
              },
              scopedSlots: _vm._u([
                {
                  key: "button",
                  fn: function(ref) {
                    var loading = ref.loading
                    return [
                      _c(
                        "div",
                        { staticClass: "text-center col-12" },
                        [
                          _c(
                            "app-button",
                            {
                              attrs: {
                                size: "lg",
                                type: "submit",
                                loading: loading
                              }
                            },
                            [
                              _vm._v(
                                "\n              Получить предложения\n            "
                              )
                            ]
                          )
                        ],
                        1
                      )
                    ]
                  }
                }
              ])
            },
            [
              _c(
                "app-form-group",
                {
                  staticClass: "col-6",
                  attrs: { label: "Город", "model-name": "city" }
                },
                [
                  _c("app-input", {
                    model: {
                      value: _vm.model.city,
                      callback: function($$v) {
                        _vm.$set(_vm.model, "city", $$v)
                      },
                      expression: "model.city"
                    }
                  })
                ],
                1
              ),
              _c(
                "app-form-group",
                {
                  staticClass: "col-6",
                  attrs: { "model-name": "target", label: "Цель кредита" }
                },
                [
                  _c("app-select", {
                    attrs: { items: _vm.targets },
                    scopedSlots: _vm._u([
                      {
                        key: "default",
                        fn: function(ref) {
                          var value = ref.value
                          return [
                            _vm._v(
                              "\n              " +
                                _vm._s(value) +
                                "\n            "
                            )
                          ]
                        }
                      }
                    ]),
                    model: {
                      value: _vm.model.target,
                      callback: function($$v) {
                        _vm.$set(_vm.model, "target", $$v)
                      },
                      expression: "model.target"
                    }
                  })
                ],
                1
              ),
              _c(
                "app-form-group",
                {
                  staticClass: "col-6",
                  attrs: { "model-name": "years", label: "Срок кредита" }
                },
                [
                  _vm._v(
                    "\n          " + _vm._s(_vm.yearText) + "\n          "
                  ),
                  _c("input", {
                    directives: [
                      {
                        name: "model",
                        rawName: "v-model",
                        value: _vm.model.years,
                        expression: "model.years"
                      }
                    ],
                    staticClass: "custom-range",
                    attrs: { type: "range", min: "1", max: "30" },
                    domProps: { value: _vm.model.years },
                    on: {
                      __r: function($event) {
                        return _vm.$set(_vm.model, "years", $event.target.value)
                      }
                    }
                  })
                ]
              ),
              _c(
                "app-form-group",
                {
                  staticClass: "col-6",
                  attrs: {
                    "model-name": "price",
                    label: "Примерная стоимость объекта"
                  }
                },
                [
                  _vm._v(
                    "\n          " +
                      _vm._s(_vm._f("currency")(_vm.model.price)) +
                      "\n          "
                  ),
                  _c("input", {
                    directives: [
                      {
                        name: "model",
                        rawName: "v-model",
                        value: _vm.model.price,
                        expression: "model.price"
                      }
                    ],
                    staticClass: "custom-range",
                    attrs: {
                      type: "range",
                      min: "200000",
                      max: "40000000",
                      step: "50000"
                    },
                    domProps: { value: _vm.model.price },
                    on: {
                      input: _vm.priceChanged,
                      __r: function($event) {
                        return _vm.$set(_vm.model, "price", $event.target.value)
                      }
                    }
                  })
                ]
              ),
              _c(
                "app-form-group",
                {
                  staticClass: "col-6",
                  attrs: { "model-name": "type", label: "Тип занятости" }
                },
                [
                  _c("app-select", {
                    attrs: { items: _vm.types },
                    scopedSlots: _vm._u([
                      {
                        key: "default",
                        fn: function(ref) {
                          var value = ref.value
                          return [
                            _vm._v(
                              "\n              " +
                                _vm._s(value) +
                                "\n            "
                            )
                          ]
                        }
                      }
                    ]),
                    model: {
                      value: _vm.model.type,
                      callback: function($$v) {
                        _vm.$set(_vm.model, "type", $$v)
                      },
                      expression: "model.type"
                    }
                  })
                ],
                1
              ),
              _c("div", { staticClass: "col-6" }, [
                _c("label", [_vm._v("Первоначальный взнос в руб. или %")]),
                _c(
                  "div",
                  { staticClass: "form-row" },
                  [
                    _c(
                      "app-form-group",
                      { staticClass: "col" },
                      [
                        _c(
                          "app-input",
                          {
                            attrs: {
                              type: "number",
                              min: "0",
                              max: _vm.model.price
                            },
                            on: { input: _vm.initialFeePriceChanged },
                            model: {
                              value: _vm.model.initialFeePrice,
                              callback: function($$v) {
                                _vm.$set(_vm.model, "initialFeePrice", $$v)
                              },
                              expression: "model.initialFeePrice"
                            }
                          },
                          [_vm._v("\n                руб.\n              ")]
                        )
                      ],
                      1
                    ),
                    _c(
                      "div",
                      { staticClass: "co-auto" },
                      [
                        _c(
                          "app-input",
                          {
                            staticClass: "credit-form__percent",
                            on: { input: _vm.initialFeePercentChanged },
                            model: {
                              value: _vm.model.initialFeePercent,
                              callback: function($$v) {
                                _vm.$set(_vm.model, "initialFeePercent", $$v)
                              },
                              expression: "model.initialFeePercent"
                            }
                          },
                          [_vm._v("\n                %\n              ")]
                        )
                      ],
                      1
                    )
                  ],
                  1
                )
              ]),
              _c(
                "app-form-group",
                {
                  staticClass: "col-md-6",
                  attrs: { label: "Ваш e-mail", "model-name": "email" }
                },
                [
                  _c("app-input", {
                    model: {
                      value: _vm.model.email,
                      callback: function($$v) {
                        _vm.$set(_vm.model, "email", $$v)
                      },
                      expression: "model.email"
                    }
                  })
                ],
                1
              ),
              _c(
                "app-form-group",
                {
                  staticClass: "col-md-6",
                  attrs: { label: "Номер телефона", "model-name": "phone" }
                },
                [
                  _c("app-phone-number-input", {
                    model: {
                      value: _vm.model.phone,
                      callback: function($$v) {
                        _vm.$set(_vm.model, "phone", $$v)
                      },
                      expression: "model.phone"
                    }
                  })
                ],
                1
              )
            ],
            1
          )
        ],
        1
      ),
      _vm._m(1)
    ]),
    _c("div", { staticClass: "credit-bank" }, [
      _c(
        "div",
        { staticClass: "credit-bank__items" },
        _vm._l(_vm.bankImages, function(img) {
          return _c(
            "div",
            { key: img, staticClass: "credit-bank__item d-inline-block" },
            [_c("img", { attrs: { src: "/images/bank_brands/" + img } })]
          )
        }),
        0
      )
    ])
  ])
}
var staticRenderFns = [
  function() {
    var _vm = this
    var _h = _vm.$createElement
    var _c = _vm._self._c || _h
    return _c("div", { staticClass: "credit-caption text-center" }, [
      _c("div", { staticClass: "credit-caption__head" }, [
        _c("h1", { staticClass: "credit-caption__title" }, [
          _vm._v(
            "\n          Хотите гарантированно получить одобрение банка на ипотеку?\n        "
          )
        ]),
        _c("h5", { staticClass: "credit-caption__subtitle" }, [
          _vm._v(
            "\n          Оставьте заявку, и мы подберем лучшее кредитное предложение.\n        "
          )
        ])
      ])
    ])
  },
  function() {
    var _vm = this
    var _h = _vm.$createElement
    var _c = _vm._self._c || _h
    return _c("div", { staticClass: "credit-card-items" }, [
      _c("div", { staticClass: "credit-card-item card card-body" }, [
        _c("div", { staticClass: "credit-card-item__icon" }, [
          _c("i", { staticClass: "fas fa-chart-line" })
        ]),
        _c("div", { staticClass: "credit-card-item__text" }, [
          _c("h4", [_vm._v("\n            82%\n          ")]),
          _c("div", [_vm._v("\n            Положительных решений\n          ")])
        ])
      ]),
      _c("div", { staticClass: "credit-card-item card card-body" }, [
        _c("div", { staticClass: "credit-card-item__icon" }, [
          _c("i", { staticClass: "fas fa-university" })
        ]),
        _c("div", { staticClass: "credit-card-item__text" }, [
          _c("h4", [_vm._v("\n            Все банки\n          ")]),
          _c("div", [
            _vm._v(
              "\n            и страховые программы в одном окне\n          "
            )
          ])
        ])
      ])
    ])
  }
]
render._withStripped = true

export { render, staticRenderFns }