var render = function() {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c("div", { staticClass: "page-wrapper page-wrapper_lg" }, [
    _c("h2", { staticClass: "text-center mb-5" }, [
      _vm._v("\n    Онлайн сопровождение сделки с недвижимостью\n  ")
    ]),
    _c("div", { staticClass: "list-info" }, [
      _c("div", { staticClass: "list-info-item" }, [
        _c("div", { staticClass: "list-info-item__icon text-primary" }, [
          _c(
            "svg",
            {
              attrs: {
                viewBox: "0 0 24 24",
                xmlns: "http://www.w3.org/2000/svg"
              }
            },
            [
              _c("path", { attrs: { d: "M0 0h24v24H0z", fill: "none" } }),
              _c("path", {
                attrs: {
                  d:
                    "M8 10H5V7H3v3H0v2h3v3h2v-3h3v-2zm10 1c1.66 0 2.99-1.34 2.99-3S19.66 5 18 5c-.32 0-.63.05-.91.14.57.81.9 1.79.9 2.86s-.34 2.04-.9 2.86c.28.09.59.14.91.14zm-5 0c1.66 0 2.99-1.34 2.99-3S14.66 5 13 5c-1.66 0-3 1.34-3 3s1.34 3 3 3zm6.62 2.16c.83.73 1.38 1.66 1.38 2.84v2h3v-2c0-1.54-2.37-2.49-4.38-2.84zM13 13c-2 0-6 1-6 3v2h12v-2c0-2-4-3-6-3z"
                }
              })
            ]
          )
        ]),
        _vm._m(0)
      ]),
      _c("div", { staticClass: "list-info-item" }, [
        _c("div", { staticClass: "list-info-item__icon text-primary" }, [
          _c(
            "svg",
            {
              attrs: {
                viewBox: "0 0 24 24",
                xmlns: "http://www.w3.org/2000/svg"
              }
            },
            [
              _c("path", { attrs: { d: "M0 0h24v24H0z", fill: "none" } }),
              _c("path", {
                attrs: {
                  d:
                    "M14 2H6c-1.1 0-1.99.9-1.99 2L4 20c0 1.1.89 2 1.99 2H18c1.1 0 2-.9 2-2V8l-6-6zm2 14h-3v3h-2v-3H8v-2h3v-3h2v3h3v2zm-3-7V3.5L18.5 9H13z"
                }
              })
            ]
          )
        ]),
        _vm._m(1)
      ]),
      _c("div", { staticClass: "list-info-item" }, [
        _c("div", { staticClass: "list-info-item__icon text-primary" }, [
          _c("svg", [_c("use", { attrs: { "xlink:href": "#icon-shield" } })])
        ]),
        _vm._m(2)
      ]),
      _c("div", { staticClass: "list-info-item" }, [
        _c("div", { staticClass: "list-info-item__icon text-primary" }, [
          _c(
            "svg",
            {
              attrs: {
                viewBox: "0 0 24 24",
                xmlns: "http://www.w3.org/2000/svg"
              }
            },
            [
              _c("path", { attrs: { d: "M0 0h24v24H0z", fill: "none" } }),
              _c("path", {
                attrs: {
                  d:
                    "M20 2h-8C6.38 2 2 6.66 2 12.28 2 17.5 6.49 22 11.72 22 17.39 22 22 17.62 22 12V4c0-1.1-.9-2-2-2zm-3 13l-3-2v2H7V9h7v2l3-2v6z"
                }
              })
            ]
          )
        ]),
        _vm._m(3)
      ]),
      _c("div", { staticClass: "list-info-item" }, [
        _c("div", { staticClass: "list-info-item__icon text-primary" }, [
          _c(
            "svg",
            {
              attrs: {
                viewBox: "0 0 24 24",
                xmlns: "http://www.w3.org/2000/svg"
              }
            },
            [
              _c("path", { attrs: { d: "M0 0h24v24H0z", fill: "none" } }),
              _c("path", {
                attrs: {
                  d:
                    "M19 3h-4.18C14.4 1.84 13.3 1 12 1c-1.3 0-2.4.84-2.82 2H5c-1.1 0-2 .9-2 2v14c0 1.1.9 2 2 2h14c1.1 0 2-.9 2-2V5c0-1.1-.9-2-2-2zm-7 0c.55 0 1 .45 1 1s-.45 1-1 1-1-.45-1-1 .45-1 1-1zm2 14H7v-2h7v2zm3-4H7v-2h10v2zm0-4H7V7h10v2z"
                }
              })
            ]
          )
        ]),
        _vm._m(4)
      ])
    ]),
    _c(
      "div",
      { staticClass: "invite__footer text-center mt-5 ng-star-inserted" },
      [
        _c("h4", [_vm._v("Стоимость от 990 руб.")]),
        _c("app-button", { staticClass: "my-5", attrs: { size: "xl" } }, [
          _vm._v("\n      Заказать\n    ")
        ]),
        _vm._m(5)
      ],
      1
    )
  ])
}
var staticRenderFns = [
  function() {
    var _vm = this
    var _h = _vm.$createElement
    var _c = _vm._self._c || _h
    return _c("div", { staticClass: "list-info-item__text" }, [
      _c("div", { staticClass: "list-info-item__title" }, [
        _vm._v("\n          Согласование условий сделки онлайн\n        ")
      ]),
      _c("div", { staticClass: "list-info-item__body" }, [
        _vm._v(
          "\n          Согласование условий проведения сделки и подготовка необходимых документов всеми\n          участниками в режиме онлайн.\n        "
        )
      ])
    ])
  },
  function() {
    var _vm = this
    var _h = _vm.$createElement
    var _c = _vm._self._c || _h
    return _c("div", { staticClass: "list-info-item__text" }, [
      _c("div", { staticClass: "list-info-item__title" }, [
        _vm._v("\n          Формирование документов\n        ")
      ]),
      _c("div", { staticClass: "list-info-item__body" }, [
        _vm._v(
          "\n          Формирование договора купли-продажи, который зарегистрирует Росреестр, и перечня\n          необходимых документов для совершения сделки.\n        "
        )
      ])
    ])
  },
  function() {
    var _vm = this
    var _h = _vm.$createElement
    var _c = _vm._self._c || _h
    return _c("div", { staticClass: "list-info-item__text" }, [
      _c("div", { staticClass: "list-info-item__title" }, [
        _vm._v("\n          Проверка юридической чистоты\n        ")
      ]),
      _c("div", { staticClass: "list-info-item__body" }, [
        _vm._v(
          "\n          Сбор и проверка необходимых документов по объекту. Проверка собственника и объекта на\n          юридическую чистоту (подготовка заключения).\n        "
        )
      ])
    ])
  },
  function() {
    var _vm = this
    var _h = _vm.$createElement
    var _c = _vm._self._c || _h
    return _c("div", { staticClass: "list-info-item__text" }, [
      _c("div", { staticClass: "list-info-item__title" }, [
        _vm._v("\n          Консультация через видеозвонок\n        ")
      ]),
      _c("div", { staticClass: "list-info-item__body" }, [
        _vm._v(
          "\n          24 часа в сутки вы сможете в оперативном режиме получить интересующую вас информацию и\n          задать вопросы специалистам.\n        "
        )
      ])
    ])
  },
  function() {
    var _vm = this
    var _h = _vm.$createElement
    var _c = _vm._self._c || _h
    return _c("div", { staticClass: "list-info-item__text" }, [
      _c("div", { staticClass: "list-info-item__title" }, [
        _vm._v("\n          Электронная регистрация в Росреестре\n        ")
      ]),
      _c("div", { staticClass: "list-info-item__body" }, [
        _vm._v(
          "\n          Регистрация перехода права собственности от продавца к покупателю без посещения\n          Росреестра и МФЦ.\n        "
        )
      ])
    ])
  },
  function() {
    var _vm = this
    var _h = _vm.$createElement
    var _c = _vm._self._c || _h
    return _c("div", { staticClass: "invite__amount small" }, [
      _vm._v("\n      Проведено более\n      "),
      _c("span", { staticClass: "btn-link" }, [_vm._v("3507 сделок")])
    ])
  }
]
render._withStripped = true

export { render, staticRenderFns }