



















































































































































































































import Vue from 'vue';
import Component from 'vue-class-component';
import clamp from 'lodash/clamp';

import CreditQuestionnare from './CreditQuestionnare.vue';
import pluralize from 'shared/utils/pluralize';
import { Validators } from 'shared/utils/validator';

@Component
export default class MortgageSelection extends Vue {
  // data()
  bankImages: string[] = [
    'ABB.png',
    'VTB.png',
    'gazprombank.png',
    'deltacredit.png',
    'domipoteka.png',
  ];

  model: SimpleObject<any> = {
    city: '',
    target: 0,
    type: 0,
    years: 1,
    price: 200000,
    initialFeePrice: 0,
    initialFeePercent: 0,
    phone: '',
    email: '',
  };

  targets: string[] = [
    'Не выбрано',
    'Вторичка',
    'Новостройка',
    'Дом с земельным участком',
    'Апартаменты',
    'Доля / Комната',
    'Рефинансирование',
    'Коммерческая недвижимость',
    'Кредит под залог имеющейся недвижимости',
  ];

  types: string[] = [
    'Не выбрано',
    'По найму',
    'Индивидуальный предприниматель',
    'Собственник бизнеса',
  ];

  // computed
  get yearText(): string {
    return `${this.model.years} ${pluralize(this.model.years, 'год', 'года', 'лет')}`;
  }

  // methods
  openDialog() {
    this.$dialog.open({ component: CreditQuestionnare });
  }

  async sendForm() {
    try {
      let template = `
        Email: ${this.model.email} <br>
        Телефон: ${this.model.phone} <br>
        Город: ${this.model.city} <br>
        Цель кредита: ${this.targets[this.model.target]} <br>
        Тип занятости: ${this.types[this.model.type]} <br>
        Срок кредита: ${this.yearText} <br>
        Примерная стоимость объекта: ${this.model.price} руб. <br>
        Первоначальный взнос: ${this.model.initialFeePrice} руб. [ ${this.model.initialFeePercent} % ]
      `;

      await this.$api.events.SendEmail({
        email: 'fazleev@realtycloud.ru',
        title: 'Одобрение кредита',
        template: template,
      });

      this.$noty.success({ text: 'Ваша заявка принята! <br> В ближайшее время наш менеджер свяжется с Вами по указанным контактам.' });
    } catch (error) {
      console.error(error);
    }
  }

  priceChanged(event: Event) {
    const el: HTMLInputElement = event.target as HTMLInputElement;
    const v = parseInt(el.value, 10);
    this.model.initialFeePrice = Math.min(this.model.initialFeePrice, v);
    this.model.initialFeePercent = (this.model.initialFeePrice / v * 100).toFixed(1);
  }

  initialFeePriceChanged(value: number) {
    this.model.initialFeePrice = clamp(value, 0, this.model.price);
    this.model.initialFeePercent = (this.model.initialFeePrice / this.model.price * 100).toFixed(1);
  }

  initialFeePercentChanged(value: string) {
    if (/\.$/.test(value)) {
      return;
    }

    let v = clamp(parseFloat(value), 0, 100);

    if (/\d+\.\d+/.test(v.toString())) {
      v = parseFloat(v.toFixed(2));
    }

    if (!isNaN(v)) {
      this.model.initialFeePercent = v;
      this.model.initialFeePrice = (this.model.initialFeePercent / 100 * this.model.price).toFixed(0);
    } else {
      this.model.initialFeePercent = 0;
      this.model.initialFeePrice = 0;
    }
  }

  validationConfig(): ValidationConfig {
    return {
      email: [ Validators.required, Validators.email ],
      phone: [ Validators.required, Validators.phone ],
      city: [ Validators.required ],
      target: [ Validators.notEq(0, 'Обязательное поле') ],
      type: [ Validators.notEq(0, 'Обязательное поле') ],
    };
  }
}
