















import Vue from 'vue';

export default Vue.extend({
  popupOptions: {
    size: 'md',
  },

  data() {
    return {
      loaded: false,
    };
  },
});
